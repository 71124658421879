@import 'theme';

$background: #f1f1f1;

.primary-c {
  color: mat-color($primary, 500);
}

.accent-border {
  border: 1px solid mat-color($accent, 500);
}

.dotted-accent-border {
  border: dotted 3px mat-color($accent, 500);
}

.primary-border {
  border: 1px solid mat-color($primary, 500);

}

.primary-border-bottom {
  border-bottom: 1px solid mat-color($primary, 500);
}

.accent-border-bottom {
  border-bottom: 1px solid mat-color($accent, 500);
}

.primary-border-top {
  border-top: 1px solid mat-color($primary, 500);
}

.accent-border-top {
  border-top: 1px solid mat-color($accent, 500);
}

.primary-bg {
  background-color: mat-color($primary, 500);
}

.primary-bg {
  background-color: mat-color($primary, 500);
}

.accent-bg {
  background-color: mat-color($primary, 500);
}

.warn-c {
  color: mat-color($warn, 500)
}

.accent-c {
  color: mat-color($accent, 500);
}

.accent-c-300 {
  color: mat-color($accent, 300);
}

.accent-c-200 {
  color: mat-color($accent, 200);
}

.accent-bg-100 {
  background-color: mat-color($accent, 100);
}

.accent-bg-200 {
  background-color: mat-color($accent, 200);
}

.accent-bg-300 {
  background-color: mat-color($accent, 300);
}

.primary-bg-200 {
  background-color: mat-color($primary, 200);
}

.background-bg {
  background-color: $background;
}

.large-width {
  width: 85vw;
}

.done-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  border-radius: 10px;
  pointer-events: none;
  background-color: rgba(0,0,0,0.2);
  
  .check-icon {
    width: 42px;
    height: 42px;
    line-height: 42px;
    font-size: 42px;
    color: mat-color($primary, 500);
  }  
}

.card-header {
  padding: 10px 10px;
  margin: 0px !important;
  box-shadow: 0 10px 18px -18px #000;

  .mat-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    cursor: pointer;
  }
}

.card-footer {
  padding: 10px 10px;
  background-color: mat-color($accent, 50);
  color: black;
  border-radius: 0 0 10px 10px;

  .mat-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    cursor: pointer;
  }
}

.screenshot-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: mat-color($primary, 500);
  opacity: 0.2;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  &.active {
      opacity: 1;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
  }

}

.gallery-container {
  padding: 12px;
}

.gallery-image {
  border-radius: 10px;
  max-width: 300px;
  width: 40vw;
  height: auto;
}

.gallery-section-description {
  font-size: 16px;
}

.gallery-section-title {
  color: mat-color($accent, 500);
  font-weight: bold;
  font-size: 18px;
}

.gallery {
  overflow: hidden;
}


.gallery-section {
  padding: 0 16px;
}

.gallery-content {
  padding: 16px 0;
  transform: translateX(0);
  transition: transform 350ms cubic-bezier( 0.5, 0, 0.5, 1 );
}

.chevron-filler {
  height: 32px;
  width: 32px;
  color: mat-color($accent, 500);
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3 ease-in-out;
  -webkit-tap-highlight-color: transparent;

  &.disabled {
      opacity: 0.2;
  }
}

.side-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 700;
  overflow: auto;
  background-color: white;


  .item-side-bar {
    padding: 10px;
  }
}

.add-item-header {
  border-bottom: 2px solid mat-color($accent, 500);
  padding: 10px;
  position: relative;

  .clear-icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
  }

  .clear-text {
    font-size: 14px;
    color: mat-color($accent, 500);
  }
}


body, button, input, textarea, select {
  font-size: 16px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #0D0D11;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: mat-color($primary, 500);
  overscroll-behavior: none;
}

.small-form .mat-form-field-wrapper {
  margin-bottom: -1.25em !important;
}

.transparent-dialog {
  .mat-dialog-container {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

gridster {
  background-color: transparent;
}

.cdk-overlay-pane {
  max-width: 90vw !important;
}

.mat-radio-label {
  width: 100% !important;
}

.mat-radio-label-content {
  width: 100% !important;
  min-width: 0;
}

.mat-horizontal-content-container {
  padding: 0 2px 2px 2px !important;
}

.primary-bg {
  background-color: mat-color($primary, 500) !important;
  color: white;
}

.warn {
  color: mat-color($warn);
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.positive-button {
  background-color: mat-color($accent, 300);
  color: white;
}

.fill-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: background-color 0.3s ease-in-out;
}


.normal-overflow {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.light-font {
  font-size: 14px;
  color: #A6A6A6;
}

.light-font-small {
  font-size: 12px;
  color: #A6A6A6;
}

.bold-font-dark {
  font-size: 18px;
  color: #000000
}

.bold-font-medium {
  font-size: 18px;
  color: #A6A6A6;
}

.bold-font-light {
  font-size: 24px;
  color: #FFFFFF
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.editting {
  background-color: #A6A6A6;
}

.editting-element {
  background-color: $background;
}

.pointer {
  cursor: pointer;
}

.slider-container {
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}

.slider {
  width: calc(300%);
  transform: translateX(calc((-100% / 3)));
}

.is-animating {
  transition: transform 200ms cubic-bezier( 0.5, 0, 0.5, 1 );
}

.slider-panel {
  position: relative;
  height: 64px;
  width: calc(100% / 3);
}


.edit-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
  padding: 10px;
  color: white;
  background-color: mat-color($accent, 500);
}

.delete-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  color: white;
  height: 100%;
  background-color: mat-color($warn, 500);
}

.modal-header {
  border-bottom: 2px solid mat-color($accent, 500);
  padding: 10px;

  .modal-close-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
  }

  .modal-close-text {
    font-size: 14px;
    color: mat-color($accent, 500);
  }

  .modal-title {
    text-align: left;
    font-size: 18px;
    color: mat-color($accent, 500);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.modal-content {
  max-height: calc(85vh - 110px);
  overflow: auto;
  padding: 10px;
  -webkit-overflow-scrolling: touch;
}

.modal-footer {
  background-color: mat-color($accent, 300);
  padding: 10px;

  .modal-footer-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
    color: white;
    transition: all 0.3s ease-in-out;

    &.disabled {
      color: #a6a6a6;
    }
  }

  .modal-footer-text {
    font-size: 14px;
    color: white;
    transition: all 0.3s ease-in-out;

    &.disabled {
      color: #a6a6a6;
    }
  }
}

.swan-mini-fab {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;

  &::ng-deep .mat-button-wrapper{
      line-height: 28px;
      padding: 0;

       .mat-icon {
           font-size: 18px;
           width: 18px;
           height: 18px;
           line-height: 18px;
       }
 }
}

.swan-accent-button {
  background-color: mat-color($accent, 300);
  color: white;
}

.mat-badge.hide-text .mat-badge-content {
  color: transparent;
  font-size: 8px !important;
  line-height: 16px !important;
}

.snackbar-success {
  color: white !important;
  background-color: mat-color($accent, 300) !important;
}

.snackbar-success .mat-simple-snackbar-action  {
  color: mat-color($accent, 500);
}

.snackbar-error {
  color: white !important;
  background-color: mat-color($warn, 500) !important;
}

.snackbar-error .mat-simple-snackbar-action  {
  color: mat-color($accent, 500);
}


.news-content {

  .article {
    width: 100%;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .sub-article {
    width: 100%;
    font-size: 10px;
    color: #a6a6a6;
    margin-bottom: 4px;
  }

  .bullet {
    width: calc(100% - 12px);
    white-space: normal;
    margin-left: 12px;
    margin-bottom: 4px;
    font-size: 14px;

    &.light {
      color: #a6a6a6;
    }
  }

}

.status-toggle {
  border-radius: 12px;
  position: relative;
  line-height: 12px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.75);
  font-style: italic;

  .status {
    transition: background-color 350ms ease-in-out;
    padding: 6px 12px;
    border-radius: 12px;
  }

  .active {
    color: white;
    font-style: normal;
    font-weight: bold;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
              0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .passed {
    position: relative;
    color: rgba(255, 255, 255, 0.50);
  }

  .passed::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 32px;
    top: 0;
    right: -16px;
    bottom: 0;
    transition: background-color 350ms ease-in-out;
    background-color: mat-color($accent, 100);
  }
  
}